import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Android from "../../images/portfolio/andriod.png";
import Apple from "../../images/portfolio/apple.png";
import Link from 'gatsby-link';
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";

function Result({data}) {
    return (
        <div className="outcomes_section" style={{
            backgroundImage: `linear-gradient(0deg, #ffffff 15%, ${checkData(data, 'bg_light_color')}  15%, ${checkData(data, 'bg_light_color')}  65%, ${checkData(data, 'bg_dark_color')}  65%)`
        }}>
            <Container>
                <Row>
                    <Col xs={12} md={10} lg={10} xl={8} xxl={8} className="m-auto text-center">
                        <h2 style={{color: `${checkData(data, 'txt_color')}`}}>{checkData(data, 'results_title')}</h2>
                        <div
                            dangerouslySetInnerHTML={{__html: checkData(data, 'results_description')}}
                            style={{color: `${checkData(data, 'txt_color')}`}}/>

                        <div className="store_links">
                            {data && data.results_android_link !== '' ?
                                <Link to={checkData(data, 'results_android_link')}>
                                    <img src={Android} alt="android" className="img-fluid"/>
                                </Link>
                                : ''}
                            {data && data.results_apple_link !== '' ?
                                <Link to={checkData(data, 'results_apple_link')}>
                                    <img src={Apple} alt="android" className="img-fluid"/>
                                </Link>
                                : ''}
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={10} lg={10} xl={8} xxl={8} className="m-auto text-center">
                        <img
                            src={`${mediaBaseURL}${checkData(data, 'results_image')}`}
                            className="img-fluid"
                            alt={checkData(data, 'results_title')}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Result;

